import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "components/Layout"
import Seo from "components/Seo"

const LegalPage = () => {
  const { t } = useTranslation()
  const title = t("Legal notices")
  const seoTitle = t("UVASCLAB, legal notices")
  const seoDescription = t("Legal notices of UVASCLAB company")

  return (
    <Layout hasHeader={true} hasPreFooter={false} title={title}>
      <Seo title={seoTitle} description={seoDescription} />
      <section className="container page-content-top lh-2">
        <h2 className="bold-40 text-center mb-4">Edition du site Internet</h2>
        <p className="light-24 mb-2">
          Le site UVASC LAB (ci-après "le Site"), accessible à l’adresse{" "}
          <a href="https://www.uvasc.io">https://www.uvasc.io</a> est édité par
          la société UVASC LAB (ci-après "l'Éditeur"), SAS au capital de 10.000
          euros, dont le siège social est situé Rue Roger Signor, 29120
          Pont-l'Abbé, France.
        </p>
        <p className="light-24 mb-2">
          <a href="mailto:contact@uvasc.io">contact@uvasc.io</a> –{" "}
          <a href="tel:02 98 82 28 42">02 98 82 28 42</a>
        </p>
        <p className="light-24 mb-2">RCS 887 997 476 QUIMPER</p>
        <p className="light-24 mb-2">TVA FR578 879 974 76</p>
        <h2 className="bold-40 text-center my-4">Rédaction du site Internet</h2>
        <p className="light-24 mb-2">
          Directeur de la publication : Mr. Wit HAERTLÉ
        </p>
        <p className="light-24 mb-2">
          Contact: <a href="mailto:admin@uvasc.io">admin@uvasc.io</a>
        </p>
        <h2 className="bold-40 text-center my-4">
          Rédaction des mentions légales
        </h2>
        <p className="light-24 mb-2">
          Dénomination sociale : UVASC LAB
          <br />
          Adresse siège social : Centre Hospitalier Hôtel-Dieu, Rue Roger
          Signor, 29120 Pont l’Abbé
          <br />
          Site internet : <a href="https://www.uvasc.io">www.uvasc.io</a>
        </p>
        <h2 className="bold-40 text-center my-4">Hébergeur du site Internet</h2>
        <p className="light-24 mb-2">
          Le site statique UVASC LAB est généré avec GATSBY et hébergé chez
          infomaniak, certifié iso 27001, SA au capital de 102 000 euros, dont
          le siège social est situé 25 Rue Eugène-Marziano, 1227 Genève, Suisse,
          joignable par le moyen suivant :{" "}
          <a href="mailto:support@infomaniak.com">support@infomaniak.com</a>.
        </p>
        <h2 className="bold-40 text-center my-4">
          Respect de la propriété intellectuelle
        </h2>
        <p className="light-24 mb-2">
          Toutes les marques, photographies, textes, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques qui pourraient être utilisées
          pour faire fonctionner le Site et plus généralement tous les éléments
          reproduits ou utilisés sur le Site sont protégés par les lois en
          vigueur au titre de la propriété intellectuelle.
        </p>
        <p className="light-24 mb-2">
          Ils sont la propriété pleine et entière de l'Éditeur ou de ses
          partenaires, sauf mentions particulières. Toute reproduction,
          représentation, utilisation ou adaptation, sous quelque forme que ce
          soit, de tout ou partie de ces éléments, y compris les applications
          informatiques, sans l'accord préalable et écrit de l'Éditeur, sont
          strictement interdites. Le fait pour l'Éditeur de ne pas engager de
          procédure dès la prise de connaissance de ces utilisations non
          autorisées ne vaut pas acceptation desdites utilisations et
          renonciation aux poursuites.
        </p>
        <p className="light-24 mb-2">
          Seule l'utilisation pour un usage privé dans un cercle de famille est
          autorisée et toute autre utilisation est constitutive de contrefaçon
          et/ou d'atteinte aux droits voisins, sanctionnées par Code de la
          propriété intellectuelle.
        </p>
        <p className="light-24 mb-2">
          La reprise de tout ou partie de ce contenu nécessite l'autorisation
          préalable de l'Éditeur ou du titulaire des droits sur ce contenu.
        </p>
        <h2 className="bold-40 text-center my-4">Liens hypertextes</h2>
        <p className="light-24 mb-2">
          Le Site peut contenir des liens hypertexte donnant accès à d'autres
          sites web édités et gérés par des tiers et non par l'Éditeur.
          L'Éditeur ne pourra être tenu responsable directement ou indirectement
          dans le cas où lesdits sites tiers ne respecteraient pas les
          dispositions légales.
        </p>
        <p className="light-24 mb-2">
          La création de liens hypertexte vers le Site ne peut être faite
          qu'avec l'autorisation écrite et préalable de l'Éditeur.
        </p>
        <h2 className="bold-40 text-center my-4">Hébergeur des données</h2>
        <p className="light-24 mb-2">
          Les applications web, les données et les données de santé sont
          hébergées près de Rennes chez BT Blue SA au capital de 8 millions
          d’euros, dont le siège est situé rue Blaise Pascal 35220 Châteaubourg,
          certifié HDS, certifié iso 27001 et avec une conception Tier III+ -
          les données sont redondées et répliquées dans un deuxième datacenter
          BT Blue en France. BT Blue est joignable par le moyen suivant :{" "}
          <a
            href="https://www.bt-blue.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bt-blue.com/contact
          </a>
        </p>
        <p className="light-24 mb-2">
          L’application mobile est à télécharger sur le magasin d’applications
          Apple. Aucune donnée personnelle ou de santé n’est stockée sur votre
          terminal mobile.
        </p>
      </section>
    </Layout>
  )
}

export default LegalPage
